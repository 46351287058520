.notice1 h3 {
    font-family: "josefin sans", sans-serif;
    font-size: 2em;
    padding-top: 2%;
    margin-bottom: -0.2em;
}

.notice1 img {
    margin: 1em 1em 0 0;
    width: 30px;
    opacity: 0.5;
}

.notice1 span {
    background: rgba(128, 128, 128, 0.41);
    width: 5.5%;
    height: 1.2px;
    display: inline-block;
    vertical-align: text-bottom;
}

.notice1 span:nth-child(2) {
    margin-right: 1em;
}

.notice-body {
    margin-top: 1em;
    padding-bottom: 1em;
    /* background-color: #f2f2f2; */
    padding-left: 14%;
    padding-right: 15%;
    /* width: 80%; */
}

.notice-body .notice-slide {
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 2em; */
    border: 1px solid green;
    border-radius: 5px;
    margin: 0 5px 1px 5px;
}

.notice-body .notice-slide hr {
    margin-top: -1px;
    color: green;
}

.notice-title h3 {
    font-family: "josefin sans", sans-serif;
    padding-top: 0.5em;
    font-size: 1.2em;
}

.notice-img {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.notice-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.gallery-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.notice-img,
.gallery-img {
    overflow: hidden;
}

.notice-img img,
.gallery-img img {
    transition: transform 0.3s;
}

.notice-img:hover img,
.gallery-img:hover img {
    transform: scale(1.2);
}

.notice-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;
}

.notice-button button {
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 6px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
    transition: background-color 0.3s;
}

.notice-button button:hover {
    animation: colorAnimation 1s infinite;
}

.notice-button a {
    text-decoration: none;
    color: white;
    font-family: 'josefin sans', sans-serif;
    text-transform: uppercase;
}

@keyframes colorAnimation {
    0% {
        background-color: green;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: green;
    }
}

.swiper-button-next,
.swiper-button-prev {
    display: none !important;
}

.swiper-pagination-bullet {
    display: none !important;
}

@media (max-width: 768px) {
    .notice1 h3 {
        font-size: 1.5em;
    }

    .notice1 img {
        width: 20px;
    }
    .notice1 {
        margin-top: 1em;
    }
    .notice-title h3 {
        font-size: 1.3em;
    }
    .notice-img img {
        width: 50%;
    }
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination-bullet {
        display: none !important;
    }
    .notice-button button {
        padding: 2px 10px;
        font-size: 15px;
    }
}