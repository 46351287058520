.project-head {
    /* padding: 2em 0 1em 8em; */
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.project-head img {
    width: 230px;
    margin-top: -2em;
    margin-bottom: -1em;
    /* background-color: red; */
}

.project-head h1 {
    font-size: 25px;
    font-weight: bold;
    color: rgb(45, 169, 45);
    position: relative;
    top: 0.8em;
    right: 0.2em;
}

.project-body {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    padding-left: 17%;
    padding-right: 12%;
    margin-top: 1em;
}

.project-body .project-body-1 {
    width: 50%;
    /* margin: 10px; */
    /* background-color: red; */
    padding: 1em;
}

.project-body .project-body-1 h3 {
    font-size: 25px;
    margin-bottom: 12px;
    color: rgb(45, 169, 45);
}

.project-body .project-body-1 ul {
    list-style: none;
    margin-bottom: -1em;
    font-size: 18px;
}

.project-body .project-body-1 li {
    margin: 15px;
    /* margin-top: 1em; */
}

@media (max-width: 768px) {
    .project-head {
        padding-left: 1em;
    }

    .project-head img {
        width: 180px;
    }

    .project-head h1 {
        position: relative;
        font-size: 20px;
        top: 0.5em;
    }

    .project-body {
        padding-left: 4%;
        padding-right: 2%;
    }

    .project-body .project-body-1 {
        width: 100%;
        /* background-color: red; */
    }
}