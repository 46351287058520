.activity-button {
    background-color: rgb(45, 169, 45);
    width: 200px;
    height: 38px;
    transition: backgound-color 0.3s;
}

.activity-button p {
    color: white;
    font-family: 'open sans', sans-serif;
    text-transform: uppercase;
    position: relative;
    top: -10px;
    /* font-size: 16px; */
}

.activity-button i {
    font-size: 25px;
    position: relative;
    top: 4px;
}

.activity-button:hover {
    animation: colorAnimation 1s infinite;
}

.body-activity {
    margin: 0 15px 0 15px;
    /* padding: 0 1em 0 1em; */
    /* background-color: red; */
}

.body-activity .label1 {
    /* font-size: 25px; */
    margin-right: -5em;
    margin-left: 1em;
}

.body-activity .label2 {
    margin-right: -3em;
    margin-left: 1em;
}

.body-activity .label3 {
    margin-left: 1em;
    margin-right: -2em;
}

.activity-img-form {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    /* background-color: red; */
}

.modal-close-btn1 {
    width: 35px;
    height: 35px;
    padding: 4px;
    color: white;
    /* background-color: rgb(45, 169, 45); */
    border: 1px rgb(45, 169, 45);
    border-radius: 42px;
    box-shadow: 0 0 0 0 rgba(2, 2, 2, 0.23);
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.23);
    background-color: rgb(45, 169, 45);
    transition: background-color 0.3s;
}

.cross1 {
    color: white;
    font-size: 32px;
    position: absolute;
    left: auto;
    bottom: auto;
    right: 17px;
    top: 12px;
}

.modal-close-btn1:hover {
    animation: colorAnimation6 1s infinite;
}

.activity-img-form .activity-img1 {
    width: 50%;
    /* background-color: gray; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
}

.activity-img-form .activity-img2 {
    width: 50%;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
}

.activity-img-form .activity-img3 {
    width: 33%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 5px;
}

.activity-img-form .activity-img1 img,
.activity-img-form .activity-img2 img,
.activity-img-form .activity-img3 img {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

.modal-title h3 {
    font-family: 'open sans', sans-serif;
    color: white;
    text-transform: uppercase;
}

.body-activity {
    font-family: 'josefin sans', sans-serif;
    color: rgb(31, 116, 31);
}

.modal-footer {
    background-color: #d3d3d3;
    border-radius: 5px;
}

.modal-footer button {
    font-family: 'open sans', sans-serif;
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 5px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1.5em;
    color: white;
    transition: background-color 0.3s;
}

.modal-footer button:hover {
    animation: colorAnimation 1s infinite;
}

.upload-box {

    position: relative;
    right: 5em;

}

