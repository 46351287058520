
.close-btn {
    position: absolute;
    height: 40px;
    width: 40px;
    left: auto;
    right: 10px;
    bottom: auto;
    top: 5px;
    padding: 4px;
    color: #fff;
    font-family: inherit;
    font-size: 20px;
    line-height: 20px;
    border: 1px #fff;
    border-radius: 42px;
    box-shadow: 0 0 0 0 rgba(2, 2, 2, 0.23);
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.23);
    background-color: rgb(45, 169, 45);
    transition: background-color 0.3s;
}

.cross-icon {
    position: absolute;
    left: auto;
    right: 4px;
    bottom: auto;
    top: 4px;
    font-size: 2rem;
    color: white;
}

.close-btn:hover {
    animation: colorAnimation1 1s infinite;
}

@keyframes colorAnimation1 {
    0% {
        background-color: red;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: red;
    }
}

.notice-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent background */
    z-index: 9999; /* Ensures the notice is displayed on top of other elements */
  }
  
  .notice {
    /* background-color: white; */
    background: url('../images/notice-logo.png') #f2f2f2 no-repeat center;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgb(45, 169, 45);
    font-size: 18px;
    text-align: center;
    margin: 15%;
  }

  .notice h2 {
    font-family: "josefin sans", sans-serif;
    padding: 5px;
    /* margin-top: -1em; */
  }

  .notice p {
    text-align: justify;
    /* margin: 10px; */
    padding: 5px;
    /* height: auto; */
  }



@media (max-width: 768px) {
    .notice {
        width: 95%;
        margin: 2%;
    }
    .notice h2 {
        font-size: 23px;
        padding: 0 0 5px 0;
        margin: 0;
    }
    .notice p {
        font-size: 16px;
        padding: 0;
        margin: 0;
    }
  }

