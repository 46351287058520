.contact-head {
    /* padding: 2em 0 1em 8em; */
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.contact-head h1 {
    font-size: 25px;
    font-weight: bold;
    color: rgb(45, 169, 45);
    position: relative;
    top: 1em;
    right: 0.4em;
}

.contact-head img {
    width: 200px;
    margin-top: -1.5em;
    margin-bottom: -1em;
    /* background-color: red; */
}

.contact-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
    /* background: url('../images//notice-logo.png') no-repeat center; */
}

.contact-body .contact-detail {
    width: 35%;
    border-radius: 5px;
    margin: 10px;
    /* background-color: #f2f2f2; */
    background: linear-gradient(to left, white, #f2f2f2);
    font-family: "josefin sans", sans-serif;
}

.contact-body .contact-detail2 {
    margin: 10px;
    width: 35%;
    /* margin-right: 10%; */
    padding-top: 2em;
    padding-right: 2em;
    border-radius: 5px;
    background: linear-gradient(to right, transparent, #f2f2f2);
    /* background-color: #f2f2f2; */
}

.contact-body .contact-detail h3,
.contact-body .contact-detail2 h3,
.contact-form-main .feedback h3 {
    font-family: "josefin sans", sans-serif;
    font-size: 25px;
    padding: 0.5em 0 0 1em;
    color: rgb(45, 169, 45);
}

.contact-body .contact-detail hr,
.contact-form-main .feedback hr {
    color: rgb(45, 169, 45);
    margin: 0;
    padding-bottom: 0.5em;
    /* border: 1px groove rgb(45, 169, 45); */
}

.contact-body .contact-detail i {
    color: rgb(45, 169, 45);
    padding-left: 3em;
    font-size: 18px;
}

.contact-body .contact-detail span {
    /* padding-left: 5em; */
    font-size: 20px;
    color: rgb(45, 169, 45);
    font-weight: bold;
}

.contact-body .contact-detail p {
    text-align: justify;
    margin: 0.5em;
    padding-left: 2em;
}

.contact-body .contact-detail2 p {
    text-align: justify;
    /* margin: 0.5em; */
}

.contact-body .contact-detail li,
.contact-body .contact-detail2 li {
    margin: 0.5em 0 0.5em 3em;
}

.contact-body .contact-detail p a {
    text-decoration: none;
    color: black;
    margin: 0.5em 0 0.5em 2.5em;
    /* padding-left: 2em; */
}

.contact-body .contact-detail .location p {
    margin: 0 0 1em 3em;
    padding-left: 2em;
}

.contact-form-main {
    width: 50%;
    padding-left: 15%;
    padding-top: 2em;
}

.contact-form-main .feedback .contact-form {
    margin: 1em 0 1em 3em;
}

.contact-form-main .feedback .contact-form input[type='text'] {
    background: transparent;
    border: 1px solid rgb(45, 169, 45);
}

.contact-form-main .feedback .contact-form textarea {
    background: transparent;
    border: 1px solid rgb(45, 169, 45);
}

.contact-form-main .feedback .contact-form .feedback-button button {
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
    transition: background-color 0.3s;
}

.contact-form-main .feedback .contact-form .feedback-button button:hover {
    animation: colorAnimation 1s infinite;
}

.error-message {
    font-size: 14px;
    padding-left: 1em;
    margin-top: 0.4em;
    color: red;
}

@keyframes colorAnimation {
    0% {
        background-color: green;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: green;
    }
}

@media (max-width: 768px) {
    .contact-head {
        padding-left: 2em;
        /* justify-content: center; */
    }

    .contact-head img {
        width: 160px;
    }

    .contact-head h1 {
        font-size: 20px;
        position: relative;
        top: 0.8em;
    }

    .contact-body {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        padding-right: 0;
        width: 100%;
    }

    .contact-body .contact-detail,
    .contact-body .contact-detail2,
    .contact-form-main {
        width: 100%;
        text-align: left;
        /* padding: 0; */
    }

    .contact-body .contact-detail p,
    .contact-body .contact-detail2 p,
    .contact-body .contact-detail .location p {
        padding: 0;
    }

    .contact-body .contact-detail,
    .contact-body .contact-detail2 {
        background: none;
    }

    .contact-body .contact-detail2 {
        /* margin-top: 0; */
        padding-top: 0;
        padding-right: 0;
    }

    .contact-detail2 h3 {
        margin-left: 5px;
    }

    .contact-form-main {
        /* width: 50%; */
        padding-left: 0;
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 4em;
    }
}