.branches-head {
    /* padding: 2em 0 1em 8em; */
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.branches-head img {
    width: 230px;
    margin-top: -2em;
    margin-bottom: -1em;
    /* background-color: red; */
}

.branches-head h1 {
  font-size: 25px;
  font-weight: bold;
  color: rgb(45, 169, 45);
  position: relative;
  top: 0.5em;
  right: 0.2em;
}

.branches-body {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    /* width: 40%; */
    padding-left: 15%;
    padding-right: 12%;
    margin-top: 1em;
    /* background-color: red; */
}

.card-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
  
  .card {
    display: flex;
    flex-direction: row;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    width: 30%;
    /* padding: 20px; */
    padding: 20px 5px;
    /* margin-bottom: 10px; */
    margin: 10px;
    /* display: inline-block; */
    transition: background-color 0.3s;
  }
  .card1 {
    width: 25%;
    border-right: 1px solid rgb(45, 169, 45);
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .card1 h3 {
    margin-top: 7px;
    /* align-items: center; */
    /* text-align: center; */
  }
  .card2 {
    width: 75%;
    padding-left: 1em;
    /* background-color: blue; */
  }

  .card:hover {
    animation: colorAnimation2 1s infinite;
    border: 1px solid rgb(45, 169, 45);
  }

  @keyframes colorAnimation2 {
    0% {
        background-color: #f2f2f2;
    }

    50% {
        background-color: white;
    }

    100% {
        background-color: #f2f2f2;
    }
}

  .card i {
    color: rgb(45, 169, 45);
    font-size: 17px;
  }

  .card h4,
  .card h5,
  .card h6 {
    /* font-size: 25px; */
    padding: 0 0 5px 0;
  }

  .card h4 {
    font-size: 20px;
  }

  .card h5 {
    font-size: 16px;
  }
  .card h6 {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    .branches-head {
      padding-left: 0;
      /* justify-content: center; */
    }
    .branches-head img {
        width: 200px;
    }
    .branches-head h1 {
      position: relative;
      font-size: 20px;
      top: 0.4em;
    }
    .branches-body {
        /* margin: 0; */
        padding-left: 0;
        padding-right: 0;
    }
    .branches-body .card-container {
      justify-content: center;
    }
    .card {
        width: 80%;
        /* margin-left: 1.5em; */
    }
    .card1 h3 {
      margin-top: 10px;
    }
    .card2 h4 {
        font-size: 18px;
    }
    .card2 h5 {
        font-size: 15px;
    }
    .card2 h6 {
        font-size: 15px;
        margin-bottom: 0;
    }
  }
  