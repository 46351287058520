
.activities1 h3 {
    font-family: "josefin sans", sans-serif;
    font-size: 2em;
    padding-top: 2%;
    margin-bottom: -0.2em;
}

.activities1 img {
    margin: 1em 1em 0 0;
    width: 30px;
    opacity: 0.5;
}

.activities1 span {
    background: rgba(128, 128, 128, 0.41);
    width: 5.5%;
    height: 1.2px;
    display: inline-block;
    vertical-align: text-bottom;
}

.activities1 span:nth-child(2){
    margin-right: 1em;
}

.activities2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
}

.activities2 .recent-event,
.activities2 .upcoming-event {
    width: 35%;
    /* border: 2px solid red; */
    /* background-color: rgb(45, 169, 45); */
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 10px;
}

.activities2 .recent-event h3,
.activities2 .upcoming-event h3 {
    font-family: "josefin sans", sans-serif;
    font-size: 20px;
    padding-top: 1em;
    /* color: white; */
}

.recent-event .item-event {
    display: flex;
    /* background-color: rgb(106, 106, 207); */
    margin: 0 10px 5px 10px;
    padding: 5px 5px;
    /* color: white; */
}

.item-event .event-icon {
    width: 25%;
    /* border: 1px solid white; */
    background-color: white;
    /* border-radius: 5px; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: column;
    font-family: 'josefin sans', sans-serif;
    color: rgb(45, 169, 45);
    padding: 10px 0 5px 0;
}

.item-event .event-icon i {
    font-size: 3em;
}

.item-event .event-icon .date {
    font-size: 25px;
    position: relative;
    top: -2.2em;
    margin-bottom: -2em;
}

.item-event .event-detail {
    background-color: rgb(45, 169, 45);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgb(45, 169, 45);
    width: 75%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
    /* margin-left: -5px; */
    color: white;
}

.recent-event hr,
.upcoming-event hr {
    /* width: 50%; */
    color: rgb(45, 169, 45);
    opacity: 1;
}

@media (max-width: 768px) {
    .activities1 {
        margin-top: 1em;
    }
    .activities1 h3 {
        font-size: 1.5em;
    }
    .activities1 img {
        width: 20px;
    }
    .activities2 .recent-event h3,
    .activities2 .upcoming-event h3 {
        font-size: 15px;
    }
    .activities2 .recent-event p,
    .activities2 .upcoming-event p {
        font-size: 15px;
    }
    .activities2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .activities2 .recent-event,
    .activities2 .upcoming-event {
        width: 70%;
    }
    .item-event .event-detail h5 {
        font-size: 15px;
    }
    .item-event .event-icon {
        width: 35%;
    }
    .item-event .event-detail {
        width: 65%;
        font-size: 15px;
    }
}

