.membership-head {
    /* padding: 2em 0 1em 8em; */
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.membership-head img {
    width: 230px;
    margin-top: -2em;
    margin-bottom: -1em;
    /* background-color: red; */
}

.membership-head h1 {
    font-size: 25px;
    font-weight: bold;
    color: rgb(45, 169, 45);
    position: relative;
    top: 0.8em;
    right: 0.2em;
}

.membership-content {
    display: flex;
    flex-direction: column;
}

.membership-download {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    /* background-color: red; */
    width: 100%;
}

.membership-Form,
.membership-modal {
    width: 30%;
    background-color: #f2f2f2;
    padding: 1em;
    margin: 0 1em;
    border-radius: 5px;
}

.membership-Form button,
.membership-modal button {
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 5px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
    text-transform: uppercase;
    transition: background-color 0.3s;
}

.membership-Form button:hover,
.membership-modal button:hover {
    animation: colorAnimation7 1s infinite;
}

@keyframes colorAnimation7 {
    0% {
        background-color: green;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: green;
    }
}

.membership-Form h3,
.membership-modal h3 {
    font-family: 'josefin sans', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    color: rgb(45, 169, 45);
}

@media (max-width: 768px) {
    .membership-head {
        padding-left: 1em;
    }

    .membership-head img {
        width: 200px;
    }

    .membership-head h1 {
        position: relative;
        font-size: 20px;
        top: 0.7em;
    }

    .membership-download {
        flex-direction: column;
    }

    .membership-content {
        margin-top: -0.4em;
    }

    .membership-Form,
    .membership-modal {
        width: 70%;
        margin: 0.5em 0;
    }
}