.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 5px;
}

.topbar .logo {
  /* margin-right: 1em; */
  /* background-color: red; */
  padding-left: 1.5em;
}

.topbar .logo img {
  width: 80px;
  height: 80px;
  /* padding-left: 1.5em; */
}

.name-section {
  align-items: center;
  text-align: center;
}

.name-section img {
  width: 40%;
}

.name {
  font-size: 18px;
  font-family: "josefin sans", sans-serif;
}

.name-section hr {
  margin: 2px;
  padding: 5px;
}

.name-2 {
  font-size: 16px;
  margin-top: -5px;
}

.login {
  width: 80px;
  background-color: rgb(45, 169, 45);
  border: 1px solid rgb(45, 169, 45);
  padding: 5px 12px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1.5em;
  /* color: white; */
  transition: background-color 0.3s;
}

.login:hover,
.left-user .nav-title:hover {
  animation: colorAnimation 1s infinite;
}

.login a {
  text-decoration: none;
  color: white;
}

.left-user .nav-title {
  background-color: rgb(45, 169, 45);
  font-size: 18px;
  color: white;
  /* background-color: #f2f2f2; */
  padding: 10px;
  border-radius: 5px;
  margin-right: 1.5em;
  font-family: 'josefin sans', sans-serif;
  transition: background-color 0.3s;

}

@keyframes colorAnimation {
  0% {
    background-color: green;
  }

  50% {
    background-color: rgb(45, 169, 45);
  }

  100% {
    background-color: green;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .topbar .logo {
    padding-left: 0;
  }

  .left-user .nav-title {
    margin-right: 0;
    font-size: 12px;
  }

  .name {
    font-size: 12px;
  }

  .name-2 {
    font-size: 12px;
    /* margin-top: -5px; */
  }

  .login {
    /* padding: 2px 8px; */
    /* padding-right: 5em; */
    /* margin-right: 5px; */
    font-size: 12px;
  }

  .login a {
    font-size: 15px;
  }

  .name-section img {
    width: 75%;
  }
}

@media (max-width: 576px) {
  .logo img {
    width: 40px;
    height: 40px;
    /* margin-left: 10px; */
  }

  .login {
    padding: 4px 8px;
  }
}