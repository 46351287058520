/* Footer container */
.footer {
    background-color: #f2f2f2;
    padding: 20px;
    display: flex;
    margin-top: 2em;
    justify-content: space-between;
    font-family: 'josefin sans', sans-serif;
    width: 100%;
}

/* Left section */
.left {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: red; */
    width: 33%;
}

.left .left-logo img {
    width: 100px;
}

.left .left-title img {
    width: 250px;
    padding-right: 1em;
}

.center {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 33%;
}

.center .center-head h3,
.right .right-head h3 {
    color: rgb(45, 169, 45);
}

.center ul,
.right ul {
    list-style: none;
}

.center li a {
    text-decoration: none;
    font-size: 18px;
    color: black;
}

.center li a:hover {
    color: rgb(45, 169, 45);
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: green; */
    width: 33%;
}

.right .right-links {
    text-align: left;
    font-size: 18px;
}

.right .social-links a {
    padding: 1em;
}

.right .social-links img {
    width: 30px;
}

.footer2 {
    background-color: #d6d6d6;
    padding: 0.5em;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.footer2 h3 {
    font-size: 18px;
    font-family: 'josefin sans', sans-serif;
    padding-top: 10px;
}

@media (max-width: 768px) {
    .left {
        flex-direction: column;
    }
    .left .left-logo img {
        width: 60px;
    }
    .left .left-title img {
        width: 150px;
        padding-right: 0;
    }
    .center {
        /* text-align: left; */
        display: none;
    }
    .right {
        width: 67%;
    }
    .right .right-links {
        font-size: 15px;
    }
    .right .social-links img {
        width: 22px;
    }
    .right .social-links a {
        padding: 5px;
    }
    .footer2 h3 {
        font-size: 12px;
    }
}
