.login-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c5c5;
    z-index: 9999;
}

.login-body {
    display: flex;
    /* background-color: red; */
    /* border: 1px solid rgb(45,169,45); */
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.login-left {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(45, 169, 45);
    border-radius: 5px;
    background-color: #f2f2f2;
    /* text-align: center; */
    align-items: center;
    padding: 1.5em;
    margin: 0.5em;

}

.login-left img {
    width: 220px;
    margin: 0.5em;
}

.login-left .logo-img {
    width: 150px;
}

.login-right {
    display: flex;
    /* width: ; */
    background-color: rgb(40, 198, 40);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4em 3em 0 2em;
    font-family: 'josefin sans', sans-serif;
    margin: 0 0.5em 0 0.2em;
    border-radius: 5px;
}

.login-right h3 {
    margin-top: -1em;
    color: white;
}

.view-password {
    color: rgb(45, 169, 45);
    /* background-color: red; */
    padding: 0;
    position: relative;
    top: -2.3em;
    left: 3.8em;
    font-size: 20px;
    margin-bottom: -1.5em;
    border: none;
}

.view-password:hover {
    color: rgb(45, 169, 45);
    border: none;
}

.login-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    /* margin-bottom: 0; */
    margin: 5px;
    /* background-color: red; */
    /* margin: 1em; */
    padding-top: 1em;
}

.login-button button {
    background-color: white;
    border: 1px solid white;
    padding: 6px 15px;
    border-radius: 4px;
    cursor: pointer;
    /* margin-top: 2em; */
    width: 30%;
    /* padding-bottom: -4em; */
    /* margin-right: 5px; */
    color: rgb(45, 169, 45);
    transition: background-color 0.3s;
    font-family: 'open sans', sans-serif;
}

.login-button button:hover {
    animation: colorAnimation8 1s infinite;
}

@keyframes colorAnimation8 {
    0% {
        background-color: white;
    }

    50% {
        background-color: #cccccc;
    }

    100% {
        background-color: white;
    }
}

.login-button text {
    margin-top: 1em;
    font-size: 13px;
    margin-bottom: 1em;
    text-decoration: underline;
}
.login-button p {
    margin-top: 5px;
    font-size: 14px;
}

.login-button a {
    text-decoration: none;
    color: white;
    font-size: 15px;
}

.login-button a:hover {
    color: black;
    cursor: pointer;
}

.login-button span {
    margin-left: 0.5em;
    border: 1px solid white;
    padding: 5px;
    font-family: 'open sans', sans-serif;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .login-body {
        flex-direction: column;
    }
    .login-left {
        flex-direction: row;
        padding: 0;
        /* margin: 0; */
    }
    .login-left img {
        width: 180px;
    }
    .login-left .logo-img  {
        width: 100px;
    }
    .login-right {
        padding: 1em;
        margin: 0;
    }
    .login-right h3 {
        margin-top: 0;
    }
    .login-right Col {
        width: 10px;
    }
    .view-password {
        left: 7em;
    }
}