/* For Modal */
.activities-show-btn button {
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 5px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
    transition: background-color 0.3s;
}

.activities-show-btn button:hover {
    animation: colorAnimation5 1s infinite;
}

@keyframes colorAnimation5 {
    0% {
        background-color: green;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: green;
    }
}

.modal-close-btn {
    width: 35px;
    height: 35px;
    padding: 4px;
    color: white;
    /* background-color: rgb(45, 169, 45); */
    border: 1px rgb(45, 169, 45);
    border-radius: 42px;
    box-shadow: 0 0 0 0 rgba(2, 2, 2, 0.23);
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.23);
    background-color: rgb(45, 169, 45);
    transition: background-color 0.3s;
}

.cross-icon1 {
    color: white;
    font-size: 30px;
    position: absolute;
    left: auto;
    bottom: auto;
    right: 18px;
    top: 10px;
}

.modal-close-btn:hover {
    animation: colorAnimation6 1s infinite;
}

@keyframes colorAnimation6 {
    0% {
        background-color: red;
    }

    50% {
        background-color: rgb(45, 169, 45);
    }

    100% {
        background-color: red;
    }
}

.activities-body-content {
    text-align: justify;
    font-size: 18px;
}

.activities-body-content p {
    margin-bottom: 5px;
}

.activities-header {
    margin-bottom: -0.5em;
}

.activities-header h3 {
    color: white;
}

.activities-body-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.activities-body-img img {
    width: 80%;
    padding: 1em;
    border-radius: 1.5em;
    transition: transform 0.3s;
}

.activities-body-img img:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    
}