.product-head {
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.product-head img {
    width: 230px;
    margin-top: -2em;
    margin-bottom: -1em;
}

.product-head h1 {
    font-size: 25px;
    font-weight: bold;
    color: rgb(45, 169, 45);
    position: relative;
    top: 0.5em;
    right: 0.2em;
}

.product-body {
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    padding-left: 17%;
    padding-right: 12%;
    margin-top: 1em;
    /* background-color: red; */
}

.product-card-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
}

.product-card {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    /* text-align: center; */
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    width: auto;
    padding: 10px 10px;
    margin: 10px;
    transition: background-color 0.3s;
}

.product-card:hover {
    animation: colorAnimation3 1s infinite;
    border: 1px solid rgb(45, 169, 45);
}

@keyframes colorAnimation3 {
    0% {
        background-color: #f2f2f2;
    }

    50% {
        background-color: white;
    }

    100% {
        background-color: #f2f2f2;
    }
}

.product-card .product-card-img {
    position: relative;
    /* padding-bottom: 1em; */
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: red; */
}

.product-card .product-card-img img {
    width: auto;
    max-width: 250px;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
}

.product-card .product-card-body p {
    /* text-align: left; */
    /* margin-bottom: -2em; */
    background-color: white;
    padding: 1.5em;
    border-radius: 5px;
    margin-left: 2em;
}

.product-card .product-card-body h3 {
    font-size: 25px;
    /* text-align: center; */
    /* padding: 2px 0 8px 0; */
    padding-bottom: 8px;
    color: rgb(45, 169, 45);
}

.product-card .product-card-body h4 {
    font-size: 18px;
    padding-bottom: 5px;
    /* padding-top: 1em; */
}

@media (max-width: 768px) {
    .product-head {
        padding-left: 0;
        /* justify-content: center; */
        /* margin: 0; */
    }
    .product-head img {
        width: 200px;
    }
    .product-head h1 {
        font-size: 20px;
        position: relative;
        top: 0.4em;
    }
    .product-body {
        padding: 0;
        /* margin: 0; */
    }
    .product-body .product-card-container {
        /* display: flex; */
        /* align-items: center; */
        /* text-align: center; */
        justify-content: center;
    }
    .product-card {
        width: 80%;
        flex-direction: column;
    }
    .product-card .product-card-img img {
        max-width: 150px;
    }
    .product-card .product-card-body {
        width: 100%;
    }
    .product-card .product-card-body p {
        /* padding: 0; */
        margin: 1em 0 0 0;
    }
    .product-card .product-card-body h3 {
        font-size: 20px;
        text-align: center;
    }

    .product-card .product-card-body h4 {
        font-size: 15px;
    }
}

