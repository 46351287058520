.home-content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: #f2f2f2; */
    /* flex-direction: column; */
    /* width: 70%; */
}

.home-content1 .content-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 2em 1em;
    width: 70%;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.home-content1 .content-top .content-top-left .content-search {
    /* background-color: red; */
    border: 1px solid rgb(45, 169, 45);
    border-radius: 5px;
    padding: 5px 10px;
    /* color: rgb(45, 169, 45); */
}

.home-content1 .content-top .content-add {
    position: relative;
    right: 5em;
}

.home-content1 .content-top .content-add button {
    background-color: rgb(45, 169, 45);
    border: 1px solid rgb(45, 169, 45);
    padding: 5px 12px;
    border-radius: 5px;
    color: white;
    font-family: 'Open sans', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s;
}

.home-content1 .content-top .content-add button:hover {
    animation: colorAnimation 1s infinite
}

.home-content1 .content-top .content-top-left i {
    position: relative;
    right: 2em;
    color: rgb(45, 169, 45);
    /* opacity: 1; */
}

.home-content1 .content-body {
    width: 70%;
}

.content-body .content-body-title h3 {
    font-family: 'josefin sans', sans-serif;
    margin-top: 1em;
    text-align: left;
    color: rgb(45, 169, 45);
}

.content-body .content-body-list {
    font-size: 15px;
}

.content-body .content-body-list Table {
    border-color: rgb(45, 169, 45);
    /* border-radius: 5px; */
}

.content-body .content-body-list th {
    color: rgb(45, 169, 45);
    font-family: 'josefin sans', sans-serif;
}

.content-body .content-body-list p {
    text-align: justify;
    /* background-color: red; */
    margin-bottom: 1px;
}

.content-body .content-body-list img {
    width: 150px;
    padding-bottom: 5px;
    /* margin: 5px; */
}

.content-body .content-body-list i {
    font-size: 20px;
    display: block;
    color: rgb(45, 169, 45);
}

.content-body .content-body-list i:hover {
    color: red;
}