.activities-head {
    background-color: #f2f2f2;
    padding-left: 15%;
    padding-top: 2em;
    padding-bottom: 1em;
    display: flex;
}

.activities-head img {
    width: 230px;
    margin-top: -2em;
    margin-bottom: -1em;
    /* background-color: red; */
}

.activities-head h1 {
    font-size: 25px;
    font-weight: bold;
    color: rgb(45, 169, 45);
    position: relative;
    top: 0.8em;
    right: 0.2em;
}

.activities-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* width: 40%; */
    /* padding-left: 15%; */
    /* padding-right: 12%; */
    margin-top: 1em;
    /* background-color: red; */
}

.activities-body .activities-card-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;
}

.activities-body .activities-card-container .activities-card {
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 20px 20px;
    margin: 10px 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    transition: background-color 0.3s;
    /* border: 1px solid red; */
}

.activities-card-img img {
    width: 400px;
    height: 250px;
    /* height: 50px; */
    object-fit: cover;
    border-radius: 5px;
}

.activities-card-name h3 {
    font-size: 23px;
    color: rgb(45, 169, 45);
    padding: 0 0 5px 0;
    /* padding-top: 1em; */
}

.activities-card-date h4 {
    text-align: right;
    font-size: 16px;
    padding: 1em 1em 0 0;
    color: rgb(45, 169, 45);
    /* background-color: red; */
}

.activities-body .activities-card-container .activities-card:hover {
    animation: colorAnimation4 1s infinite;
    border: 1px solid rgb(45, 169, 45);
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    /* background-color: red; */
    padding: 5px;
    margin-bottom: 1em;
    /* width: 70%; */
}

.pagination button {
    display: inline-block;
    padding: 5px 12px;
    margin: 0 5px;
    border: none;
    border-radius: 43px;
    background-color: #f2f2f2;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: rgb(45, 169, 45);
}

.pagination button.active {
    background-color: rgb(45, 169, 45);
    color: #fff;
}

@keyframes colorAnimation4 {
    0% {
        background-color: #f2f2f2;
    }

    50% {
        background-color: white;
    }

    100% {
        background-color: #f2f2f2;
    }
}

@media (max-width: 768px) {
    .activities-head {
        padding-left: 1em;
    }

    .activities-head img {
        width: 170px;
    }

    .activities-head h1 {
        position: relative;
        font-size: 20px;
        top: 0.4em;
    }

    .activities-body .activities-card-container {
        justify-content: center;
    }

    .activities-body .activities-card-container .activities-card {
        width: 80%;
    }

    .activities-card-img img {
        width: 250px;
        /* height: 100px; */
    }
}