.head-3 h3 {
    font-family: "josefin sans", sans-serif;
    font-size: 2em;
    padding-top: 2%;
    margin-bottom: -0.2rem;
}

.head-3 img {
    margin: 1em 1em 0 0;
    width: 30px;
    opacity: 0.5;
}

.head-3 span {
    background: rgba(128, 128, 128, 0.41);
    width: 5.5%;
    height: 1.2px;
    display: inline-block;
    vertical-align: text-bottom;
}

.head-3 span:nth-child(2) {
    margin-right: 1em;
}

.content-1 {
    background-color: #f2f2f2;
    padding: 2em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 1em; */
}

.content-1 .introduction {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    /* padding-left: 15%; */
    /* padding-right: 15%; */
}

.introduction h4 {
    /* font-family: "josefin sans", sans-serif; */
    font-size: 1.6em;
    text-align: left;
}

.introduction hr {
    width: 15em;
    margin-top: -1px;
    border: 1px solid rgb(45, 169, 45);
    border-radius: 5px;
    opacity: 1;
}

.introduction p {
    text-align: justify;
    font-size: 18px;
}

.introduction-image img {
    border-radius: 5px;
}

.content-2 {
    background-color: #f2f2f2;
    padding: 2em 1em;
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content-2 .content-body {
    display: flex;
    flex-direction: column;
    width: 70%;
    /* padding-right: 15%; */
    /* padding-left: 15%; */
}

.content-2 .content-body h3 {
    text-align: left;
    font-size: 1.6em;
    /* padding-bottom: 10px; */
}

.content-2 .content-body ul {
    /* padding: 1em; */
    font-size: 18px;
    text-align: justify;
    margin-bottom: -0.5em;
}

.content-2 .content-body ul li {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .head-3 h3 {
        font-size: 1.5em;
    }

    .head-3 img {
        width: 20px;
    }

    .content-1 .introduction,
    .content-2 .content-body {
        width: 80%;
    }

    .content-1 .introduction h4,
    .content-2 .content-body h3 {
        font-size: 1.3em;
    }

    .content-1 .introduction p,
    .content-2 .content-body ul {
        font-size: 16px;
    }

    .introduction hr {
        width: 12em;
    }

    .head-3 {
        margin-top: 1em;
    }
}