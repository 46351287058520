.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(45, 169, 45);
    padding: 10px;
    transition: top 0.3s;
    font-family: 'open sans', sans-serif;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    text-transform: uppercase;
}

.nav-links a {
    text-decoration: none;
    color: white;
    padding: 1px 18px;
    border-radius: 4px;
    display: inline-block;
}

.nav-links hr {
    /* margin: 0; */
    margin-top: 1px;
    margin-bottom: -16px;
    opacity: 1;
}

.nav-links p {
    display: none;
}

.nav-links span {
    display: block;
    text-align: center;
    margin-top: -6px;
}

.nav-links a.active {
    border: 1px solid white;
}

.menu-toggle {
    display: none;
}

.menu-toggle i {
    color: white;
}

.responsive {
    flex-direction: column;
}

.responsive .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
}

.responsive .nav-links.open {
    max-height: 350px;
    /* adjust the height as needed */
}

.responsive .nav-links.open li {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

@media (max-width: 1080px) {
    .nav-links ul {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .navbar {
        justify-content: right;
        position: relative;
        padding: 16px;
    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 15px;
    }

    .nav-links {
        display: none;
    }

    .nav-links ul {
        flex-direction: column;
        font-size: 15px;
        padding: 0;
        margin: 0;
    }

    .nav-links li

    .nav-links hr {
        display: none;
    }

    .nav-links a {
        display: flex;
        padding: 0;
        margin: 0;
    }
    .nav-links span {
        /* display: inline; */
        margin-top: 0;
        padding-left: 10px;
        font-size: 16px;
    }
    .nav-links p {
        display: flex;
        margin: 0;
        padding-left: 10px;
    }
}